import { ContentfulApiClient } from 'vmfcom-lib';
import { getEnvironmentCode, getLambdaToken } from '../util/config';

const env = getEnvironmentCode();
const api = new ContentfulApiClient('VMFCom', env, {
  additionalHeaders: {
    'lambda-token': getLambdaToken(),
  },
});

export const getLpTemplate = async pageUrl => {
  const res = await api.query('LpTemplate', 'pageUrl', pageUrl);
  if (res.errors?.length) {
    res.errors.forEach(err => {
      console.error(err);
    });
  }
  return res?.items?.[0];
};

export const getBlogPost = async slug => {
  const res = await api.query('BlogPostPage', 'slug', slug);
  if (res.errors?.length) {
    res.errors.forEach(err => {
      console.error(err);
    });
  }
  return res?.items?.[0];
};
