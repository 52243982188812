import styled from 'styled-components';

export default styled.section`
    .hero-image-wrapper {
        display: flex;
        width: 100%;   
        border-top: 28px solid white;
        flex-wrap: nowrap;             

        .down-payment-wrapper {
            position: relative;
            background: #FFC50A;
            flex: 1 1 50%;
            justify-content: center;
            align-content: center;
            width: 100%;
            padding: .5rem;
           

            h1, h3, p {
                color: #000;
                text-align: center;
                font-style: normal;
                font-weight: 700;
            }

            h1 {
                font-size: 78px;
                line-height: 66px;
                margin-bottom: 2rem;
                letter-spacing: -2px;

                @media(max-width: ${props => props.theme.breakpoints.desktopLarge}) {
                    font-size: 51px;
                    line-height: 39px;
                    letter-spacing: -1px; 
                }

                @media(max-width: 798px) {
                    font-size: 32px;
                    line-height: 30px;
                    margin-bottom: 1rem;
                   
                }
            }
            h3 {
                font-size: 33px;
                letter-spacing: -1px;
                margin: 0;

                @media(max-width: ${props => props.theme.breakpoints.desktopMedLarge}) {
                    font-size: 18px;
                    line-height: 21px;
                    letter-spacing: -0.56px; 
                }

                @media(max-width: ${props => props.theme.breakpoints.tablet}) {
                    font-size: 15px;
                    line-height: 15px;
                    
                   
                }
            }

            p {
                font-size: 15px;
                line-height: 18px;
                font-family: "source-sans-pro", sans-serif;
                
                

                @media(max-width: ${props => props.theme.breakpoints.desktopMedLarge}) {
                    font-size: 12px;
                    line-height: 10.078px;
                    
                     
                }
            }
        }

        img.flex-item {
            flex: 1 1 50%;
            width: 50%;
            height: 100%;
            object-fit: cover;
            box-sizing: border-box;

            @media(max-width: 599px) {
                display: none;
            }
        }

        .pill-image-wrapper {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);

            @media(max-width: ${props => props.theme.breakpoints.desktopMedLarge}) {
                top: -25px; 
                }

            img{
                width: 100%;
                @media(max-width: 599px) {
                width: 50%;
                transform: translate(10%, 0);
            }
                @media(max-width: ${props => props.theme.breakpoints.mobileSmall}) {
                    width: 80%;
                }
            }
            
        }
    }

`;