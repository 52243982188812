import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useLocation, Link } from 'react-router-dom';
import { loadModel, getColors } from 'vmfcom-lib/utils';
import CustomUrl from '../../../../util/url/CustomUrl';
import createGTMClass from '../../../../util/createGTMClass';
import noop from '../../../../util/noop';

const automations = [
  {
    whenUrlMatches: {
      match: /^\/applynow/gim,
      properties: {
        target: '_blank',
      },
    },
  },
];

const linkStyles = props => css`
  color: ${props.textColor || props.theme.colors.darkBlue};
  font-size: 1rem;
  transition: all 0.3s;
  cursor: pointer;
  display: inline-block;
  width: auto;
  font-weight: 700;
  box-sizing: border-box;

  &:hover,
  &:active {
    color: ${props =>
      props.buttonColor || props.textColor || props.theme.colors.darkBlue};
  }

  &.${props.primaryClass} {
    &--button {
      background-color: ${props.buttonColor || 'transparent'};
      border: ${`1px solid ${props.buttonColor}`};
      text-decoration: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 1.125rem 2rem;

      &:hover {
        background: ${props =>
          props.buttonColor && props.textColor
            ? props.textColor
            : 'transparent'};
        border: ${props =>
          props.buttonColor ? `1px solid ${props.buttonColor}` : 'none'};
        color: ${props =>
          props.buttonColor || props.textColor || props.theme.colors.darkBlue};
      }
    }

    &--text-link {
    }
  }
`;

const RouterLinkStyles = styled(Link)`
  ${props => linkStyles(props)}
`;
const StandardLinkStyles = styled.a`
  ${props => linkStyles(props)}
`;

const CustomLink = ({
  $data = {}, // TODO add borderColor
  newTab = null,
  onClick = noop,
}) => {
  const primaryClass = 'link';
  const content = loadModel($data, 'VMFCom', 'Link');
  const location = useLocation();

  const pathWithQuery = useMemo(() => {
    const url = new CustomUrl(content?.link);
    url.joinQuery(location.search);
    return url.fullUrl;
  }, [content?.link, location]);

  const classes = useMemo(() => {
    const c = [primaryClass];
    c.push(
      `${primaryClass}--${content.displayLinkAs.toLowerCase().replace(/ /gim, '-')}`
    );

    if (content?.gtmEvent) {
      c.push(
        createGTMClass(content?.gtmEvent, 'paragraph', content?.displayLinkAs)
      );
    }

    return c.join(' ');
  }, [content?.displayLinkAs]);

  const colors = getColors(content?.buttonAndTextColors);

  const useRouterLink = useMemo(() => {
    if (newTab === true) return false;
    if ($data?.link[0] === '/' || $data?.link[0] === '#') return true;
    return false;
  }, [$data, pathWithQuery, newTab]);

  const target = useMemo(() => {
    if (newTab === true) return '_blank';
    if ($data?.link[0] === '#') return '_self';
    if ($data?.link[0] !== '/') return '_blank';
    return '_self';
  }, [$data, newTab]);

  const handleLinkClick = useMemo(() => {
    if ($data?.link[0] === '#') {
      return e => {
        e.preventDefault();
        window.history.pushState('', document.title, e.target.href);
        const elem = document.getElementById($data.link?.replace('#', ''));
        if (elem) elem.scrollIntoView({ behavior: 'smooth' });
        onClick();
      };
    }

    return onClick;
  }, [$data]);

  const linkProps = useMemo(() => {
    const output = {
      target,
      rel: 'noopener noreferrer',
      isButton: $data?.displayLinkAs === 'Button',
      textColor: colors.secondary,
      buttonColor: colors.primary,
      className: classes,
      primaryClass: primaryClass,
      onClick: handleLinkClick,
    };

    if (useRouterLink) {
      output['to'] = pathWithQuery;
    } else {
      output['href'] = pathWithQuery;
    }

    // Overrides
    automations.forEach(automation => {
      if (automation.whenUrlMatches) {
        const { match, properties } = automation.whenUrlMatches;
        if (content.link.match(match)) {
          for (let p in properties) {
            output[p] = properties[p];
          }
        }
      }
    });

    return output;
  }, [
    target,
    $data,
    colors,
    pathWithQuery,
    classes,
    primaryClass,
    useRouterLink,
  ]);

  if (useRouterLink) {
    return (
      <RouterLinkStyles {...linkProps}>{$data?.linkText}</RouterLinkStyles>
    );
  }

  return (
    <StandardLinkStyles {...linkProps}>{$data?.linkText}</StandardLinkStyles>
  );
};

export default CustomLink;
