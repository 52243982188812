import styled from 'styled-components'

export default styled.div`
  * {
    font-family: acumin-pro;
    box-sizing: border-box;    
  }
  .promo-lp-template-text-section.with-media {   
    .text-section__inner {
      min-height: none;
      justify-content: center;
      gap: 60px;
      align-items: center;
      max-width: none;
    }  

    .text-section__media{
      max-width: 684px;
    }

    .text-section__content { 
      max-width: 684px;
      justify-content: center;
      margin-top: 0;    
      align-items: left;

      h3{
        font-weight: normal;
      }     

      &__intro{        
        p, a{
          color: ${props => props.theme.colors.white};
          line-height: normal;
          margin-bottom: 12px;
          font-family: acumin-pro;
        }

        a{
          text-decoration-line: none;
        }
        a:hover{
          text-decoration-line: underline;
        }
      }

      &__heading {
        margin-top: 0;
        font-size: 32px;
        margin-bottom: 39px;
        font-weight: normal; 
        color: ${props => props.theme.colors.white};   
      }     

    }

    @media(max-width: ${props => props.theme.breakpoints.desktopMed}) {
      justify-content: center;
      align-items: center;
      .text-section__inner{
        flex-direction: column-reverse;
        gap: 40px;
        padding: 70px 0 120px 0;        
      }
    }

    @media(max-width: ${props => props.theme.breakpoints.tablet}){
      
      .text-section__inner{
        margin: 0;
        padding: 40px 20px;
        gap: 20px;
        mac
      }
      .text-section__content{
        font-size: 14px;
        &__heading{
          font-size: 30px;
          margin-bottom: 20px;
          line-height: unset;
        }
        &__intro{
          p, a{
            margin-bottom: 10px;
            font-size: 14px;
          }
        }
      }
      .text-section__media{
        min-height: none;
      }
    }
  }
  
  .promo-lp-template-text-section{
    min-height: fit-content;
    
    .text-section__inner{
      padding: 80px 25px;      
    }

    .text-section__content{
      margin-top: 0;
      &__heading{
        color: ${props => props.theme.colors.darkBlue};
        margin: 0 0 50px;
        font-weight: 700;
        font-size: 24px;
      }
    }
    
    @media(max-width: ${props => props.theme.breakpoints.desktopMed}){
      .text-section__inner{
        padding: 60px;
      }
    }

    @media(max-width: ${props => props.theme.breakpoints.tablet}){
      .text-section__inner{
        padding: 60px 20px;
      }

      .text-section__content{
        
        &__heading{
          font-size: 24px;
          line-height: unset;
        }        
      }
    }
  }
}
`