import React from 'react';
import Styles from './Promo24Q3.styles';
import ReactMarkdown from 'react-markdown';
import Picture from '../../../Shared/Picture';

const Promo24Q3 = ({ entries }) => {
  return (
    <Styles>
      <div className='hero-image-wrapper'>
        <div className='down-payment-wrapper'>
          <ReactMarkdown source={entries?.heroContent?.header} />
          <ReactMarkdown source={entries?.heroContent?.intro} />
          <ReactMarkdown source={entries?.heroContent?.disclaimer} />
          <div className='pill-image-wrapper'>
            {entries.heroLogo.map(logo => (
              <img
                src={logo.colorLogo.file.url}
                alt={logo.colorLogo.description || 'Logo'}
              />
            ))}
          </div>
        </div>
        <img
          src={entries?.heroImage?.media?.file?.url}
          className='flex-item'
          alt={entries?.heroImage?.description}
        />
      </div>
    </Styles>
  );
};

export default Promo24Q3;
