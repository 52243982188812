import React, { useMemo } from 'react';
import Styles from './InteractiveHomePartnersSection.styled';
import InView from '../../../Shared/InView';
import sa from '../../../../styles/Components/SimpleAnimation';
import { loadModel } from 'vmfcom-lib/utils';
import createClasslist from '../../../../util/createClassList';

const InteractiveHomePartnersSection = ({ $data, className }) => {
  const primaryClass = 'interactiveHomePartnersSection';
  const classes = useMemo(
    () => createClasslist(primaryClass, className),
    [className]
  );
  // TODO logoSize: "small" | "medium" | "large"
  const content = loadModel($data, 'VMFCom', 'InteractiveHomePartnersSection');

  return (
    <InView>
      <Styles primaryClass={primaryClass} className={classes} $data={content}>
        {content?.sectionHeader && (
          <sa.h2 className={`${primaryClass}__header`} slideDirection='up'>
            {content?.sectionHeader}
          </sa.h2>
        )}
        <sa.div
          className={`${primaryClass}__partners-list`}
          delay={0.4}
          slideDirection='up'
        >
          {content?.logos?.length &&
            content.logos.map((logo, idx) => (
              <sa.a
                slideDirection='up'
                delay={0.4 + 0.2 * (idx + 1)}
                href={logo?.link || null}
                rel='noopener noreferrer'
                target='_blank'
                className={`${primaryClass}__partner`}
              >
                {logo?.monochromeLogo?.file?.url && (
                  <img
                    src={logo.monochromeLogo.file.url}
                    alt={logo.monochromeLogo?.description}
                    className={`${primaryClass}__partner-logo__logo ${primaryClass}__partner-logo__logo--monochrome`}
                  />
                )}
                {logo?.colorLogo?.file?.url && (
                  <img
                    src={logo.colorLogo.file.url}
                    alt={logo.colorLogo.description}
                    className={`${primaryClass}__partner-logo__logo ${primaryClass}__partner-logo__logo--color`}
                  />
                )}
              </sa.a>
            ))}
        </sa.div>
      </Styles>
    </InView>
  );
};

export default InteractiveHomePartnersSection;
