import styled from 'styled-components'

export default styled.div`
  * {
    font-family: acumin-pro;
    box-sizing: border-box;
  }

  div.text-section__inner{
    padding: 96px 24px;
    gap: 128px;
    
    @media(max-width: ${props => props.theme.breakpoints.desktopLarge}) {
        gap: 76px;
    };

    @media(max-width: ${props => props.theme.breakpoints.desktopMedLarge}) {
      gap: 41px;
      flex-direction: column;
      max-width: 535px;
      padding: 86px 24px;

      div.text-section__content{
        padding-left: 0;
      }
    };

    @media(max-width: ${props => props.theme.breakpoints.tablet}) {
      max-width: 380px;
    };
  }

  .text-section__content {
    justify-content: center;
    align-items: center;
    padding-left: 0;   
    max-width: 492px;   

    h1 {
      font-size: 42px;
      line-height: 44px;
      font-weight: 700;
      letter-spacing: -0.525px;
      padding-bottom: 1rem;  
    }         
       
    em {
      font-style: normal;          
    }      

    h2 {
      font-size: 28px;
    }
  
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }  
  
  .text-section__content__links{
    margin-top: 32px;      
  }

  .text-section__content__disclaimer{
    padding-top: 24px;
    color: ${props => props.theme.colors.grey1};
  }
  
  .disclaimer {
    color: ${props => props.theme.colors.white};

    .inner * {
      font-family: source-sans-pro;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: ${props => props.theme.colors.white};

      strong {
        color: ${props => props.theme.colors.black};
      }
    }
    
    a {
      color: ${props => props.theme.colors.white};
      text-decoration: underline;
    }

    .inner p:first-child strong:first-child {
      font-size: 17px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: -0.5px;
    }

    em {
      text-decoration: underline;
      font-weight: 600;
    }    
  }
  
  .cta_content {
    display: flex;
    flex-direction: column;
    padding: 96px 24px;
    justify-content: center;
    align-items: center;

    @media(max-width: ${props => props.theme.breakpoints.desktopMed}) {
      padding: 64px 24px;
    };

    h2 {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px; 
      letter-spacing: -0.35px;
      font-family: 'acumin-pro'; 
      margin: 0;

      @media(max-width: ${props => props.theme.breakpoints.tablet}) {
        font-size: 26px;
      };
    }   
    
    p {
      margin: 0;
      margin-top: 9px;
      margin-bottom: 22px;  
      line-height: 27px; 
      font-family: 'source-sans-pro';
      text-align: center;
      max-width: 807px;
      color: ${props => props.theme.colors.grey1};
      
      @media(max-width: ${props => props.theme.breakpoints.desktopMed}) {
        max-width: 535px;
      };

      @media(max-width: ${props => props.theme.breakpoints.tablet}) {          
        max-width: 380px;
      };      
    }
  }  
`
