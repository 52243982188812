import styled from 'styled-components';

export default styled.div`
  ${({ theme, resultsHeight, backgroundColor, headerTextColor }) => `
    background-color: ${backgroundColor || theme.colors.babyBlueTint};
    min-height: 36.625rem;
    position: relative;
    overflow: hidden;
    
    h1 {
      ${headerTextColor ? `color: ${headerTextColor};` : ''}
    }
    
    .find-a-home-center-heading {
      text-align: center;
      margin: 0;
      padding: 0;
      margin-top: 4.9375rem;
      font-weight: 700;
    }

    .find-a-home-center-error {
      text-align: center;

      p {
        ${headerTextColor ? `color: ${headerTextColor};` : ''}
      }
      
    }

    .find-a-home-center-search-wrapper{
      position: relative;
      z-index: 2;
    }

    .find-a-home-center-results {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 56.1875rem;
      margin: auto;
      height: auto;
      margin-top: 2.4375rem;
      margin-bottom: 9.4375rem;
      ${resultsHeight ? `height: ${resultsHeight}px;` : ''}

      &__card {
        width: 17.925rem;
        box-sizing: border-box;
        height: 100%;

        .dealer_card {
          box-sizing: border-box;
          height: 100%;

          p.type-h6 {
            color: ${theme.colors.blackAndGrey};
          }
          
        }
        
      }
      
    }

    //TODO: Remove happy houses
    // .find-a-home-center-illustration {
    //   position: absolute;
    //   bottom: 0;

    //   &__left {
    //     left: 0;
    //   }

    //   &__right {
    //     right: 0;
    //   }
      
    // }

    @media (max-width: ${theme.breakpoints.desktop}) {
      padding: 0 1.5625rem;
      
      .find-a-home-center-heading {
        margin-top: 2.875rem;
      }

      .find-a-home-center-illustration {
        display: none;
      }

      .find-a-home-center-input-wrapper {
        max-width: 31.64125rem;
        margin-top: 1.375rem;
      }

      .find-a-home-center-results {
        flex-direction: column;
        max-width: 31.64125rem;
        height: auto;
        margin-top: 1.375rem;
        margin-bottom: 4.4375rem;

        &__card {
          width: 100%;
          margin-bottom: 0.875rem;

          &:last-of-type {
            margin-bottom: 0;
          }

          .dealer_card {
            width: 100%;
          }
          
        }
        
      }
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
      .find-a-home-input-wrapper input {
        font-size: 1.125rem;
      }

      .find-a-home-center-heading {
        margin-top: 2.875rem;
      }

    }
    
  `}
`
