import React, { useEffect, useMemo, useRef, useState } from 'react';
import Styles from './FindAHomeCenter.styled';
import createClassList from '../../util/createClassList';
//TODO: Remove happy houses
// import HouseHillsAndTrees1 from '../../components/Shared/Illustrations/HouseHillsAndTrees1';
// import HouseHillsAndTrees2 from '../../components/Shared/Illustrations/HouseHillsAndTrees2';
import SearchIcon from '../../components/Shared/Icons/Search';
import LoadingIcon from '../../components/Shared/Icons/Loading';
import InView from '../../components/Shared/InView';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';
import DealerCard from '../../components/Shared/DealerCard';
import { getSuggestions, getGeocode } from '../../services/geocode';
import useGTM from '../../hooks/useGTM';
import { getDealers } from '../../services/dealers';
import DealerSearch from '../DealerSearch/DealerSearch';

const FindAHome = ({
  className = '',
  maxCards = 3, // value greater than 3 will break for the time being
  showIllustrations = true,
  heading = 'Find a Home Center Near You',
  searchPlaceholderText = 'Enter a City & State or Zip',
  charactersBeforeSearch = 3,
  zipcodeLength = 5,
  backgroundColor,
  headerTextColor,
  triggerGTMEvent = () => {},
}) => {
  const resultsRef = useRef(null);
  const [resultsHeight, updateResultsHeight] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [geocode, setGeocode] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [error, setError] = useState(false);

  const fetchDealers = async (zipcode = search) => {
    try {
      if (zipcode !== lastSearch) {
        setDealers([]);
        setIsSearching(true);

        const data = await getDealers(zipcode);
        setIsSearching(false);
        setLastSearch(zipcode);

        if (data?.data?.length && data?.data?.length > 0) {
          setError(false);
          setDealers(data.data);
        } else {
          setError(
            "We couldn't find any retailers in your area, please try a different search."
          );
        }

        triggerGTMEvent();
      }
    } catch (err) {
      setIsSearching(false);
      setError(err);
    }
  };

  const fetchSuggestions = async (_search = search) => {
    try {
      setIsSearching(true);
      const data = await getSuggestions(_search);
      setError(false);
      setIsSearching(false);
      setSuggestions(
        data.data.map(({ cityStateFormatted, postalCode }) => ({
          value: postalCode,
          label: cityStateFormatted,
        }))
      );
    } catch (err) {
      setSuggestions([]);
      setIsSearching(false);
      setError(err);
    }
  };

  const handleSearchInputChange = ({ target: { value } }) => {
    setSearch(value);
    if (value.length >= charactersBeforeSearch && isNaN(value)) {
      fetchSuggestions(value);
    } else if (value.length < charactersBeforeSearch) {
      setSuggestions([]);
    } else if (value.length === zipcodeLength && !isNaN(value)) {
      fetchDealers(value);
    }
  };

  const handleBlur = e => {
    if (
      search &&
      search.length === zipcodeLength &&
      !isNaN(search) &&
      search !== lastSearch
    ) {
      fetchDealers();
    }
  };

  const handleSubmit = e => {
    e?.stopPropagation();
    e?.preventDefault();

    if (
      search &&
      search.length === zipcodeLength &&
      !isNaN(search) &&
      search !== lastSearch
    ) {
      setDealers([]);
      fetchDealers();
    }
  };

  useEffect(() => {
    getGeocode()
      .then(data => {
        setGeocode(data.data);
        setIsLoading(false);

        if (
          data.data &&
          data?.data?.cityStateFormatted &&
          data?.data?.postalCode
        ) {
          setSuggestions([
            {
              label: data.data.cityStateFormatted,
              value: data.data.postalCode,
            },
          ]);
          setSearch(data.data.postalCode);
          fetchDealers(data.data.postalCode);
        }
      })
      .catch(console.error);
  }, []);

  const InputIcon = useMemo(
    () => (isSearching ? LoadingIcon : SearchIcon),
    [isSearching]
  );

  const dealersToShow = useMemo(() => {
    return dealers.slice(0, maxCards);
  }, [dealers, maxCards]);

  useEffect(() => {
    const sortedHeights = [...resultsRef.current.children]
      .sort((a, b) => (a.clientHeight < b.clientHeight ? 1 : -1))
      .map(({ clientHeight }) => clientHeight);

    if (sortedHeights[0]) {
      updateResultsHeight(sortedHeights[0]);
    }
  }, [dealersToShow]);

  return (
    <InView>
      <Styles
        className={createClassList(className)}
        resultsHeight={resultsHeight}
        backgroundColor={backgroundColor}
        headerTextColor={headerTextColor}
      >
        <div className='find-a-home-center-content'>
          <SimpleAnimation.h1
            className='find-a-home-center-heading'
            slideDirection='up'
            delay={0.4}
          >
            {heading}
          </SimpleAnimation.h1>
          {!isLoading && (
            <SimpleAnimation.div
              delay={0.6}
              slideDirection='up'
              className='find-a-home-center-search-wrapper'
            >
              <DealerSearch
                label={searchPlaceholderText}
                search={search}
                suggestions={suggestions}
                touched={!!geocode?.postalCode}
                onBlur={handleBlur}
                onSubmit={handleSubmit}
                onChange={handleSearchInputChange}
                isSearching={isSearching}
                error={
                  !suggestions.length &&
                  search.length >= charactersBeforeSearch &&
                  isNaN(search)
                    ? 'No matches found'
                    : false
                }
              >
                <InputIcon />
              </DealerSearch>
            </SimpleAnimation.div>
          )}
          {error && (
            <div className='find-a-home-center-error'>
              <SimpleAnimation.p className='type-h2' slideDirection='up'>
                {error}
              </SimpleAnimation.p>
            </div>
          )}
          <div ref={resultsRef} className='find-a-home-center-results'>
            {dealersToShow.map((dealer, i) => (
              <SimpleAnimation.div
                className='find-a-home-center-results__card'
                delay={0.8 + i * 0.2}
                key={`find-a-home-dealer-card-${i}`}
                slideDirection='up'
              >
                <DealerCard {...dealer} />
              </SimpleAnimation.div>
            ))}
          </div>
        </div>
        {/* //TODO: Remove happy houses
        {
          showIllustrations && (
            <>
              <HouseHillsAndTrees1 className="find-a-home-center-illustration find-a-home-center-illustration__left" />
              <HouseHillsAndTrees2 className="find-a-home-center-illustration find-a-home-center-illustration__right" />
            </>
          )
        } */}
      </Styles>
    </InView>
  );
};

export default FindAHome;
