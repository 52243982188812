import React from 'react';
import ReactMarkdown from 'react-markdown';
import theme from '../../styles/theme';
import Styles from './TvaDiscountinued.styled';
import SEO from '../../components/SEO/SEO';
import Layout from '../../components/Layout/Layout';
import Loading from '../../components/Loading/Loading';
import ErrorComponent from '../../components/Error/Error';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import MainContent from '../../components/Contentful/v2/MainContent/MainContent';
import { loadModel } from 'vmfcom-lib/utils';
import { getLpTemplate } from '../../services/contentful';

const TvaDiscontinued = ({ data, err, isLoading }) => {
  if (err) {
    console.error(err);
    return (
      <Layout>
        <ErrorComponent />
      </Layout>
    );
  }

  const entry = loadModel(data, 'VMFCom', 'LpTemplate');

  if (isLoading) return null;

  return (
    <Layout isLoading={isLoading}>
      <SEO title={data?.titleTag} description={data?.metaDescription} />
      <Loading isLoading={isLoading} />
      <Styles>
        <MainContent entries={entry?.mainContent} />
        {/* Pre Footer CTA (actions) */}
        {entry?.disclaimer && (
          <Disclaimer
            background={theme.colors.white}
            dark
            className='disclaimer'
          >
            <ReactMarkdown
              source={entry.disclaimer}
              parserOptions={{ commonmark: true }}
            />
          </Disclaimer>
        )}
      </Styles>
    </Layout>
  );
};

TvaDiscontinued.getInitialProps = async ({ match }) => {
  const { pageUrl } = match.params;
  if (typeof window === 'undefined') {
    try {
      const data = await getLpTemplate('/discontinued-tva-energyright-loan/');
      return { data: data };
    } catch (err) {
      return {
        err,
      };
    }
  } else {
    window.location.reload();
  }
};

export default TvaDiscontinued;
