import styled from 'styled-components';
import { getColors } from 'vmfcom-lib/utils';

export default styled.section`
  &.${props => props.primaryClass} {
    box-sizing: border-box;
    width: 100%;
    min-height: 20.96625rem;
    text-align: center;
    padding-top: 5.616875rem;
    padding-bottom: 2.86125rem;
    background-color: ${props => getColors(props.$data?.sectionBackgroundColor).primary || props.theme.colors.white};

    h2 {
      color: ${props => getColors(props.$data?.headerTextColor)?.primary || props.theme.colors.darkBlue};
      margin: 0;
      padding: 0;
      margin-bottom: 1.05625rem;
      font-weight: 700;
    }

    &__partners-list {
      margin: auto;2
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;

      &__partner {
        margin-right: 1.903125rem;

        &__logo {
          position: relative;

          &--monochrome {
            position: relative;
            height: 5.1875rem;
          }

          &--color {
            position: absolute;
            height: 5.1875rem;
            top: 0;
            left: 0;
            z-index: 3;
            opacity: 0;
            transition: all 0.3s;

            &:hover, &:active {
              opacity: 1;
            }
            
          }
          
        }
        
      }
      
    }
  }
`
